<!-- =========================================================================================
    File Name: i18n.vue
    Description: I18n demo
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="mb-8">
      <b-row>
        <b-col lg="8">
      <import-excel :onSuccess="loadDataInTable" />
    </b-col>
    <b-col lg="4">
      <b-button block variant="primary" @click="download()" class="mb-1">Download Template</b-button>
      <b-card>

        <b>Date</b> : YYYY-MM-DD<br>
        <b>Time</b> : HH:mm<br>
        <b>Type</b> : REGULAR / UNIQUE
      </b-card>
    </b-col>
  </b-row>
    </div>
    <div>
    <b-card v-if="tableData.length && header.length">
      <b-button v-if="tableData.length > 0 && is_checked === true" class="m-1" variant="primary" @click="import_now()">Submit</b-button>
      <b-table
        :items="tableData"
        :fields="header"
        hover
        responsive
      />
    </b-card>
  </div>
  </div>
</template>

<script>
import ImportExcel from '@core/components/excel/ImportExcel.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BLink,BAlert,BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormTextarea,BTable, BListGroup, BListGroupItem, BFormSelect, BBadge, BRow, BCol, BCard, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton } from 'bootstrap-vue'
export default {
  components: {
    ToastificationContent,
    ImportExcel,
    BLink,
      BInputGroup, BInputGroupPrepend, BInputGroupAppend,  BFormTextarea,BAlert,
      BTable,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BBadge,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
  data () {
    return {
      tableData: [],
      header:  ['class_date', 'room_id', 'start','end','type',{key:'start_date',variant:'warning'},{key:'end_date',variant:'warning'},{key:'weekday_number',variant:'warning'},'class_name','instructor','maximum','minimum'],
      sheetName: '',
      is_checked : true
    }
  },
  methods: {
    download() {
      window.location.href='https://www.cloudfit.pro/static/class-import-template.xlsx'
    },
    import_now() {
      console.log(this.tableData)
       this.$http.post(process.env.VUE_APP_API_BASE+'/class/import/',{data : this.tableData})
        .then(res => {
          if (res.data.result === true) {
         this.tableData = []
         this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
    loadDataInTable ({ results, header, meta }) {
    //  console.log(this.tableData)
    //console.log(this.sheetName)
    this.is_checked = true
      let row_number = 1
      results.forEach(row => {
        let blank = {
          start : null,
          end : null,
          class_date : null,
          type: null
        }
        console.log(row )
        if (row.class_date.substring(4,5) != "-" || row.class_date.substring(7,8) != "-" || row.class_date.length != 10 ) {
          blank.class_date = 'danger'
        }
        console.log(row.start)
        try {
          if (row.start.substring(2,3) != ":" || row.start.length != 5 ) {
            blank.start = 'danger'
            this.is_checked = false
          }
        } catch (e) {
          blank.start = 'danger'
          this.is_checked = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: "第"+row_number+"行之開始時間內容錯誤",
            },
          })
        }

        try {
          if (row.end.substring(2,3) != ":" || row.end.length != 5 ) {
             blank.end = 'danger'
             this.is_checked = false
          }
        } catch (e) {
          blank.end = 'danger'
          this.is_checked = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: "第"+row_number+"行之結束時間內容錯誤",
            },
          })
        }

        if (row.type != "UNIQUE" && row.type != "REGULAR" ) {
           blank.type = 'danger'
           this.is_checked = false
        }
        if (parseInt(row.maximum) < 1  ) {
           blank.maximum = 'danger'
        }
        row._cellVariants =  blank
        row_number += 1
      });
      console.log(results)
      this.header = header
      this.tableData = results
      this.sheetName = meta.sheetName
    }

  }
}
</script>
